.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: #fff;
  overflow: hidden; }
  .preloader.hide {
    opacity: 0;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    -webkit-transition-delay: .5s;
         -o-transition-delay: .5s;
            transition-delay: .5s; }
  .preloader__persent {
    position: absolute;
    bottom: 30%;
    left: 0;
    width: 0%;
    height: 2px;
    background: #253d90;
    -webkit-border-radius: 50px;
            border-radius: 50px;
    -webkit-transition: width .3s;
    -o-transition: width .3s;
    transition: width .3s; }
    @media screen and (orientation: landscape) {
      .preloader__persent {
        bottom: 10%; } }



.loaderSpin {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #253d90;
  -webkit-animation: loaderSpin 2s linear infinite;
  animation: loaderSpin 2s linear infinite; }

.loaderSpin:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #74b5dd;
  -webkit-animation: loaderSpin 3s linear infinite;
  animation: loaderSpin 3s linear infinite; }

.loaderSpin:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f36c26;
  -webkit-animation: loaderSpin 1.5s linear infinite;
  animation: loaderSpin 1.5s linear infinite; }

@-webkit-keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
